<template>
  <div class="order-mock-list">
    <h1>Order List</h1>
    <table>
      <thead>
        <tr>
          <th>Order ID</th>
          <th>Customer Name</th>
          <th>Product</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders" :key="order.id">
          <td>{{ order.id }}</td>
          <td>{{ order.customerName }}</td>
          <td>{{ order.product }}</td>
          <td>{{ order.quantity }}</td>
          <td>{{ order.price }}</td>
          <td>{{ order.status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'OrderMockList',
  data() {
    return {
      orders: [
        { id: 1, customerName: 'John Doe', product: 'Product A', quantity: 2, price: '$20', status: 'Shipped' },
        { id: 2, customerName: 'Jane Smith', product: 'Product B', quantity: 1, price: '$10', status: 'Pending' },
        // Add more mock orders here
      ]
    };
  }
};
</script>

<style scoped>
.order-mock-list {
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}
</style></template>
